export const options = [
  {
    key: "",
    label: "sidebar.dashboard",
    leftIcon: "dashboard",
  },

  // hide for some time as per calling

  // {
  //   key: 'periodicals',
  //   label: 'sidebar.periodicals',
  //   leftIcon: 'solution'
  // },
  {
    key: "report",
    label: "sidebar.report",
    leftIcon: "file-text",
    children: [
      {
        key: "cleaning_audit",
        label: "sidebar.cleaning_audit",
      },
      {
        key: "education",
        label: "sidebar.education",
      },
      {
        key: "maintenance_report",
        label: "sidebar.maintenance_report",
      },
      {
        key: "housekeeping_report",
        label: "sidebar.housekeeping_report",
      },
      {
        key: "equipment_register",
        label: "sidebar.equipment_register",
      },
      {
        key: "risk_assessment",
        label: "sidebar.risk_assessment",
      },
      {
        key: "safe_operating_procedures",
        label: "sidebar.safe_operating_procedures",
      },
      // {
      //   key: 'periodical',
      //   label: 'sidebar.periodical',
      // },

      // {
      //   key: 'survey',
      //   label: 'sidebar.survey',
      // },

      //=============== temporary hidethese menu =========================

      // {
      //   key: 'equipment_maintenance',
      //   label: 'sidebar.equipment_maintenance',
      // },
      // {
      //   key: 'key_swipe',
      //   label: 'sidebar.key_swipe',
      // },
      //=============================================

      // hide for some time as per calling

      // {
      //   key: 'meetings',
      //   label: 'sidebar.meetings',
      // },
      // {
      //   key: 'outbreaks',
      //   label: 'sidebar.outbreaks',
      // },

      // end hide for some time as per calling

      {
        key: "police_check",
        label: "sidebar.police_check",
      },
      // {
      //   key: 'air-purifier',
      //   label: 'sidebar.air_purifire',
      // },
      //   key: 'immunisation',
      //   label: 'sidebar.immunisation',
      // },
      // {
      //   key: 'KPI_inspection',
      //   label: 'sidebar.KPI_inspection',
      // }
    ],
  },

  // hide for some time as per calling

  // {
  //   key: 'chemical',
  //   label: 'sidebar.chemical',
  //   leftIcon: 'experiment',
  //   children: [
  //     {
  //       key: 'chemical_register',
  //       label: 'sidebar.chemical_register',
  //     },
  //     {
  //       key: 'sds',
  //       label: 'sidebar.sds',
  //     },
  //   ]
  // },

  {
    key: "certificates",
    label: "sidebar.certificates",
    leftIcon: "safety-certificate",
    children: [
      // {
      //   key: "CM3_certificate",
      //   label: "sidebar.CM3_certificate",
      // },
      {
        key: "ISO_certificates",
        label: "sidebar.ISO_certificates",
      },
      {
        key: "public_liability",
        label: "sidebar.public_liability",
      },
      {
        key: "workcover",
        label: "sidebar.workcover",
      },
    ],
  },
];

async function myOptions() {
  var newOption = [];
  let org_id = localStorage.getItem("org_id");
  // var self = this;
  // if(org_id === null){
  //   setTimeout(function(){
  //     org_id = localStorage.getItem('org_id');
  //     org_id = parseInt(org_id)
  //     newOption = options.filter(function(x,i){
  //       if (org_id ===3 || org_id ===15 || org_id ===5 || org_id ===11){
  //         if(x.children !== undefined && x.key === 'report'){
  //           x.children[x.children.length] ={"key":"KPI_inspection" ,"label":"sidebar.KPI_inspection", children: [
  //             {
  //               key: 'executive_KPI_report',
  //               label: 'sidebar.executive_KPI_report',
  //             },
  //             {
  //               key: 'facility_KPI_reports',
  //               label: 'sidebar.executive_KPI_report',
  //               // label: 'sidebar.facility_KPI_reports',
  //             }]};
  //         }
  //       }

  //       if (org_id ===16 ){
  //         if(x.children !== undefined && x.key === 'report'){
  //           x.children[x.children.length] ={"key":"transition_program" ,"label":"sidebar.transition_program", children: [
  //             {
  //               key: 'transition_gantt_chart',
  //               label: 'sidebar.transition_gantt_chart',
  //             }]};
  //         }
  //       }

  //       if (org_id ===5){
  //         if(x.children !== undefined && x.key === 'report'){
  //           x.children[x.children.length] ={"key":"cleaning_excellence" ,"label":"sidebar.cleaning_excellence"};
  //         }
  //       }
  //       if (org_id ===1 || org_id ===4 || org_id ===5){
  //         if(x.children !== undefined && x.key === 'report'){
  //           x.children[x.children.length] ={"key":"landry_audit" ,"label":"sidebar.landry_audit"};
  //               }
  //             }
  //             return x
  //           });
  //           // export default newOption;

  //           // self.setState({
  //             //   menu_list : newOption
  //             // });
  //           },1000)
  //         }else{
  //           org_id = parseInt(org_id)
  //           newOption = options.filter(function(x,i){
  //             if (org_id ===3 || org_id ===15 || org_id ===5 || org_id ===11){
  //               if(x.children !== undefined && x.key === 'report'){
  //                 x.children[x.children.length] ={"key":"KPI_inspection" ,"label":"sidebar.KPI_inspection"};
  //               }
  //             }
  //             if (org_id ===5){
  //               if(x.children !== undefined && x.key === 'report'){
  //                 x.children[x.children.length] ={"key":"cleaning_excellence" ,"label":"sidebar.cleaning_excellence"};
  //               }
  //             }
  //             if (org_id ===1 || org_id === 4 || org_id === 5){
  //               if(x.children !== undefined && x.key === 'report'){
  //                 x.children[x.children.length] ={"key":"landry_audit" ,"label":"sidebar.landry_audit"};
  //               }
  //             }
  //             if (org_id ===16 ){
  //               if(x.children !== undefined && x.key === 'report'){
  //                 x.children[x.children.length] ={"key":"transition_program" ,"label":"sidebar.transition_program", children: [
  //                   {
  //                     key: 'transition_gantt_chart',
  //                     label: 'sidebar.transition_gantt_chart',
  //                   }]};
  //               }
  //             }

  //             return x
  //           });

  //           // self.setState({
  //             // menu_list : newOption
  //             // });
  //           }

  return newOption;
}
export default myOptions;

// export default options;
