import { message, notification } from "antd";
import { Map } from "immutable";
import { requestToSendEMail } from "../actions/actions";
import { store } from "../redux/store";
import { TOKEN } from "./constants/localstoregeConstants";
import { postRequest } from "./intersepters/apiUtils";

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export const useLocalStoreDetails = (keys = []) => {
  let localStorageData = Array();
  for (let i = 0; i < keys.length; i++) {
    localStorageData.push(localStorage.getItem(keys[i]));
  }
  return localStorageData;
};

export const isValidObject = (obj) => {
  return obj && Object.keys(obj).length > 0;
};

export const getData = (pdf) => {
  return new Promise(async (reject, resolve) => {
    await postRequest({ url: `get_presigned_url`, payload: { url: pdf } })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          window.open(data.result, "_blank");
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      })
      .finally(() => {
        resolve({ loading: false });
      });
  });
};

export const sendNotification = ({ type, message, duration }) => {
  const options = {
    duration: duration || 3000,
  };

  notification[type]({
    ...options,
    message: message,
  });

  setTimeout(() => {
    store.dispatch(requestToSendEMail([]));
  }, 2000);
};

export const getAllRequiredHeaders = ({ rbu = "", ruep = "", url }) => {
  // rbu = request base url
  // ruep = request url end point

  let headers = {};

  headers["rbu"] = rbu;

  if (url.startsWith("login")) {
    headers["token-id"] = 0;
  } else {
    headers["token-id"] = localStorage.getItem(TOKEN);
  }

  headers["device-type"] = "AML9327442";

  return {
    ...headers,
  };
};

export const fileUpload = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (e) => {
      resolve({ dataURI: e.target.result, fileName: file.name });
    };

    reader.readAsDataURL(file);
  });
};

export const appendCDN = () => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");

    script.type = "text/javascript";
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.1/js/bootstrap.bundle.min.js";
    document.body.appendChild(script);

    resolve();
  });
};
