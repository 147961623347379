import axios from "axios";
import { MYM_WEB } from "../constants/rbuConstants";

const client = axios.create({
  headers: {
    "content-type": "application/json",
    accept: "application/json",
  },
});

// Update base URL interceptor
client.interceptors.request.use(
  (config) => {
    switch (config.headers.rbu) {
      case MYM_WEB:
        config["baseURL"] = process.env.REACT_APP_MYM_API_URL;
        break;
      default:
        config["baseURL"] = process.env.REACT_APP_API_URL;
        break;
    }

    if (config.url.startsWith(`client_auto_login`)) {
      config.headers["token-id"] = config.params.token;
    }

    delete config.headers["rbu"];

    return config;
  },
  (error) => {
    console.log("error :>> ", error);
    return Promise.reject(error);
  }
);

// Error handling interceptor
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }
);

export default client;
